/// <reference path="../../adonisrc.ts" />
/// <reference path="../../config/inertia.ts" />

import('../css/public.css')
import.meta.glob(['../../resources/images/**'])
import { createSSRApp, h } from 'vue'
import type { DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from '@adonisjs/inertia/helpers'
import { Link } from '@inertiajs/vue3'
import ClientDefaultLayout from '@/layouts/client/default.vue'
import ThemeDefaultLayout from '@/layouts/theme.vue'

const appName = import.meta.env.VITE_APP_NAME || 'Footy Tipping'

createInertiaApp({
  progress: { color: '#5468FF' },

  title: (title) => `${title} - ${appName}`,

  resolve: async (name) => {
    if (name.startsWith('themes/')) {
      const themes = import.meta.glob<DefineComponent>('../themes/**/*.vue')
      const resolvedPage = await resolvePageComponent(`../${name}.vue`, themes)
      resolvedPage.default.layout = resolvedPage.default.layout || ThemeDefaultLayout
      return resolvedPage
    }

    const pages = import.meta.glob<DefineComponent>('../pages/**/*.vue')
    const resolvedPage = await resolvePageComponent(`../pages/${name}.vue`, pages)
    resolvedPage.default.layout = resolvedPage.default.layout || ClientDefaultLayout
    return resolvedPage
  },

  setup({ el, App, props, plugin }) {
    createSSRApp({ render: () => h(App, props) })
      .use(plugin)
      .component('Link', Link)
      .mount(el)
  },
})
