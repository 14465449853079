<script setup lang="ts">
import { CircleUserRound, Search } from 'lucide-vue-next'

defineProps<{
  messages: Record<string, string | Record<string, string>>
}>()


const currentYear = new Date().getFullYear()

const navMenu = [
  { name: 'Dashboard', href: '/client' },
  {
    name: 'Tipping', submenu: [
      { name: 'Competitions', href: '/client/competition' },
      { name: 'Websites', href: '/client/website' },
      { name: 'Tippers', href: '/client/tipper' },
      { name: 'Setup wizard', href: '/client/competition/create' },
    ]
  },
  { name: 'Billing', href: '/client/billing' },
  { name: 'Documentation', href: '/client/docs' },
]
</script>

<template>
  <div class="flex min-h-screen w-full flex-col">
    <header class="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-50">
      <Link href="/" class="flex items-center gap-2 text-lg font-semibold md:text-base">
      <FTLogo size="small" :text="false" title="Footy Tipping" />
      </Link>
      <NavMenu :menu="navMenu" />
      <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <div class="ml-auto flex-1 sm:flex-initial">

        </div>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="secondary" size="icon" class="rounded-full text-foreground hover:text-primary">
              <CircleUserRound class="h-5 w-5" />
              <span class="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>
              <Link href="/contact">Support</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <Link href="/client/logout">Logout</Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
    <main class="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
      <slot />
    </main>
  </div>
  <footer class="py-6 md:px-8 md:py-0">
    <div class="container flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row">
      <div class="text-center text-sm leading-loose text-muted-foreground md:text-left"><span class="inline-block">
          &copy; {{ currentYear }} <a href="https://footytipping.app" target="_blank"
            class="underline underline-offset-4 font-semibold decoration-foreground">Footy Tipping</a></span>
      </div>
    </div>
  </footer>
  <ToastManager :messages="messages" />
</template>
