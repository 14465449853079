export type CompetitionSettings = {
  registrationEndsAtRound: number // The round at which registrations will close.
  tipperLimit: number // The maximum number of tippers to allow in a competition before registrations close
  clientApproveRound: boolean // Client must approve round/results before emails are sent
  signupCode: string | undefined // Tippers must enter a sign up code to join competition.
  email: boolean // Should the system automatically send emails
  globalLadderLimit: number // The maximum tippers to show in a ladder - used to hide real number of tippers from tippers
  tippingClosesBy: TippingCloseEnum // If tipping is round by round or game by game
  closeMinutes: number // The number of minutes before a game/round that tipping closes
  noTipsGet: NoTipsEnum // The points non-tippers get given if they don't tip
  showFuture: boolean // Display future rounds/tips to tippers
  tipFuture: boolean // Allow tipping of future tips
  allMarginGames: boolean // All games for all rounds are margin games
  futureTippingRounds: number // The number of future tips to allow tipping
  allowSubComps: boolean // Allow tippers to create sub-competitions
  showTipperList: boolean // Show a list of tipper's display names to all tippers
  public: boolean // Can the competition be seen to sign up to by non-logged in users
  sendEmail: string | undefined // The email address to send emails from
}

export enum NoTipsEnum {
  HOME = 'HOME',
  AWAY = 'AWAY',
  ZERO = 'ZERO',
}

export enum TippingCloseEnum {
  ROUND = 'ROUND',
  GAME = 'GAME',
}

export enum TipperLogType {
  CREATE_LADDER = 'ladder.create',
  JOIN_LADDER = 'ladder.join',
  JOIN_LADDER_FAIL = 'ladder.join_fail',
  LEAVE_LADDER = 'ladder.leave',
  REGISTER = 'register.success',
  LOGIN = 'login.success',
  RESEND_VERIFICATION_FAIL = 'register.resend_verification_fail',
  VERIFIED = 'register.verified',
  SEND_VERIFICATION_EMAIL = 'register.send_verification_email',
  JOIN_COMPETITION = 'register.join_competition',
  RECORD_TIPS = 'tipping.success',
  RECORD_TIPS_FAIL = 'tipping.failure',
  LOGOUT = 'login.logout',
  LOGIN_FAIL = 'login.failure',
  FORGOT_FAIL = 'login.forgot_fail',
  RESET_FAIL = 'login.reset_fail',
  PASSWORD_RESET = 'login.password_reset',
  SEND_RESET_EMAIL = 'login.send_reset_email',
  PROFILE_UPDATE = 'login.profile_update',
  COMPETITION_UNSUBSCRIBE = 'competition.unsubscribe',
  SEND_TIP_REMINDER = 'email.tip_reminder',
  SEND_ROUND_RESULT = 'email.round_result',
}

export type CompetitionRoundContext = {
  id: number
  seasonId: number
  number: number
  createdAt: string | null
  updatedAt: string | null
  start: string | null
  end: string | null
  approved: Boolean | null
  complete: Boolean | null
  fixtureCount: number | null
}

export type CompetitionContext = {
  id: number
  name: string
  settings: CompetitionSettings
  client: {
    name: string
  }
  sport: {
    name: string
  }
  season: {
    id: number
    year: number
    sport: {
      id: number
      name: string
    }
  }
  rounds: CompetitionRoundContext[]
  active: boolean
  paid: boolean
}

// Used to list competitions in client admin interface
export type ClientCompetition = {
  id: number
  name: string
  active: boolean
  paid: boolean
  season_id: number
  website_id: number
  website: {
    identifier: string
  } | null
  season: {
    year: number
    sport_id: number
    sport: {
      name: string
    }
  }
}
