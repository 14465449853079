import type { Updater } from '@tanstack/vue-table'
import type Competition from '#models/competition'
import type Fixture from '#models/fixture'
import type Round from '#models/round'
import { CompetitionContext, TippingCloseEnum } from '#types/competition'
import { WebsiteContext } from '#types/website'
import { type ClassValue, clsx } from 'clsx'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'
import { defineAsyncComponent, Ref } from 'vue'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value = typeof updaterOrValue === 'function' ? updaterOrValue(ref.value) : updaterOrValue
}

// Locate a theme component based on the hierarchy of the website and competition, resorting to base if no other theme is found
// Modified to return an async component directly
export function themeComponent(
  name: string,
  website: WebsiteContext | undefined,
  competition: CompetitionContext | undefined
) {
  return defineAsyncComponent(async () => {
    if (!website) {
      throw new Error('Website not found')
    }
    try {
      const module = await import(
        `../themes/${website.identifier}/${competition?.id}/components/${name}.vue`
      )
      return module.default
    } catch (_e) {
      try {
        const module = await import(`../themes/${website.identifier}/components/${name}.vue`)
        return module.default
      } catch (_e2) {
        try {
          const module = await import(`../themes/base/components/${name}.vue`)
          return module.default
        } catch (_e3) {
          throw new Error(`Component ${name} not found`)
        }
      }
    }
  })
}

export const isTippable = async (
  competition: CompetitionContext,
  fixture: Fixture,
  startOfFirstFixture: string,
  activeRound: Round
): Promise<boolean> => {
  let closingTime =
    typeof fixture.startDateTime === 'string'
      ? DateTime.fromISO(fixture.startDateTime)
      : fixture.startDateTime

  if (competition.settings.tippingClosesBy === TippingCloseEnum.ROUND) {
    // Closing time needs to change to the start of the first fixture in the round
    closingTime = DateTime.fromSQL(startOfFirstFixture)
  }

  // Closing time becomes earlier if close minutes is set, if its 0 this has no effect
  closingTime = closingTime.minus({ minutes: competition.settings.closeMinutes })

  if (competition.settings.tipFuture && closingTime > DateTime.now()) {
    // The round is not closed and Future tipping is allowed.
    if (competition.settings.futureTippingRounds > 0) {
      // With an exception... if we only allow future tipping a few rounds.
      if (fixture.round.number <= activeRound.number + competition.settings.futureTippingRounds) {
        return true
      }
    } else {
      return true // No limit, can future tip everything
    }
  } else if (
    !competition.settings.tipFuture &&
    fixture.round.number === activeRound.number &&
    closingTime > DateTime.now()
  ) {
    return true
  }

  return false
}

/** Should this fixture display as a margin game? */
export const isMarginGame = (
  competition: Competition | CompetitionContext,
  fixture: Fixture
): boolean => {
  if (competition.settings.allMarginGames === true || fixture.marginGame === true) {
    return true
  }
  return false
}

/**
 * Has the game been scored?
 * @param fixture
 * @returns
 */
export const isScored = (fixture: Fixture) => {
  return fixture.winnerId !== null || fixture.isDraw || false
}
