<script setup lang="ts">

const sizes = {
  small: {
    textSize: 'text-xl',
    logoWidth: 50
  },
  default: {
    logoWidth: 100,
    textSize: 'text-3xl'
  }
}

const props = withDefaults(defineProps<{
  size?: 'small' | 'default',
  text?: boolean
}>(), {
  size: 'default',
  text: true
})
</script>

<template>
  <div class="flex items-center rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80">
    <img src="/resources/images/footy-tipping-logo.webp" :width="sizes[props.size].logoWidth" class="mr-4" />
    <div v-if="props.text" class="text-bold {{ sizes[props.size].textSize }} dark:text-white">footy tipping</div>
  </div>
</template>
