<script setup lang="ts">
import type { CompetitionContext } from '#types/competition'
import type { WebsiteContext } from '#types/website'
import { onMounted, ref } from 'vue'
import { themeComponent } from '../lib/utils'

const props = defineProps<{
  website: WebsiteContext,
  competition?: CompetitionContext,
  messages: Record<string, string | Record<string, string>>
}>()

// Dynamically load the header component based on the theme
const Wrapper = themeComponent('wrapper', props.website, props.competition)

onMounted(() => {
  const themeElement = document.querySelector('.ft-theme') as HTMLElement
  if (themeElement) {
    themeElement.style.setProperty('--primary', props.website.settings.color1)
    themeElement.style.setProperty('--primary-foreground', props.website.settings.textColor1)
    themeElement.style.setProperty('--secondary', props.website.settings.color2)
    themeElement.style.setProperty('--secondary-foreground', props.website.settings.textColor2)
    themeElement.style.setProperty('--ring', props.website.settings.color1)
    themeElement.style.setProperty('--accent', props.website.settings.color1)
    themeElement.style.setProperty('--accent-foreground', props.website.settings.textColor1)
  }
  loaded.value = true
})

// This stops the page from flickering when the theme is loaded
const loaded = ref(false)
</script>

<template>
  <div class="ft-theme" v-show="loaded">
    <Suspense>
      <Wrapper :website="website" :competition="competition">
        <slot />
      </Wrapper>
      <!-- loading state via #fallback slot -->
      <template #fallback>
        Loading...
      </template>
    </Suspense>
    <ToastManager :messages="messages" />
  </div>
</template>
